import type { Auction } from '@medusajs/medusa';
import { AuctionGrid, type AuctionListProps } from '@storefront/ui-components/auctions/AuctionGrid';
import type { PaginationConfig } from '@storefront/ui-components/shared/pagination';
import type { FC } from 'react';
import { PaginationWithContext } from '../shared/Pagination/pagination-with-context';

export interface AuctionListWithPaginationProps extends AuctionListProps {
  auctions?: Auction[];
  paginationConfig?: PaginationConfig;
  context: string;
}

export const AuctionListWithPagination: FC<AuctionListWithPaginationProps> = ({
  context,
  paginationConfig,
  ...props
}) => (
  <div>
    <AuctionGrid {...props} />
    {paginationConfig && <PaginationWithContext context={context} paginationConfig={paginationConfig} />}
  </div>
);
