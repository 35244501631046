import { FC } from 'react';
import { ClockIcon } from '../icons';

export interface AuctionEndSoonProps {
  className?: string;
}

export const AuctionEndSoon: FC<AuctionEndSoonProps> = ({ className }) => {
  return (
    <div className="relative inline-flex items-center">
      <div className="bg-white w-4 h-4 absolute rounded-full border border-transparent ml-1" />
      <ClockIcon size={24} className={className} />
    </div>
  );
};
