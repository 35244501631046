import { type FC, type PropsWithChildren, type ReactNode } from 'react';
import { SectionHeading } from '../content/SectionHeading';
import { SectionText } from '../content/SectionText';

export interface ProductListHeaderProps extends PropsWithChildren {
  className?: string;
  heading?: ReactNode;
  text?: { value: string };
}

export const ProductListHeader: FC<ProductListHeaderProps> = ({ heading, children, text }) => {
  if (!(heading || children) && !text?.value) return null;

  return (
    <header className="mb-4 flex flex-col items-start gap-2 md:mb-6 md:flex-row md:gap-4 lg:mb-8">
      <div className="w-full flex-1 md:w-auto">
        <div className="inline-grid !max-w-prose gap-6">
          {(heading || children) && <SectionHeading>{heading || children}</SectionHeading>}
          {text && <SectionText>{text.value}</SectionText>}
        </div>
      </div>
    </header>
  );
};
