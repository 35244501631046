import type { Auction } from '@medusajs/medusa';
import useAuctionData from '@storefront/ui-components/hooks/useAuctionData';
import clsx from 'clsx';
import { type FC } from 'react';
import { CardLabel, CardThumbnail } from '.';
import { ProductSold } from '../../assets/icons/product';
import { PetOwnersBadge, ProductConditionBadge } from '../badges';
import { AuctionEndSoon } from '../badges/AuctionEndSoon';
import { DiscountedPriceLabel } from '../labels/DiscountedPriceLabel';
import { Card } from './Card';
import { CardContent } from './CardContent';
import { CardImageLabel } from './CardImageLabel';
import { CardTitle } from './CardTitle';

export interface AuctionCardProps {
  className?: string;
  auction: Auction;
  action: {
    label: string;
    onClick?: () => void;
  };
  currency: Intl.NumberFormatOptions['currency'];
}

export const AuctionCard: FC<AuctionCardProps> = ({ className, currency, auction, action }) => {
  const {
    isGoodForPets,
    originalPrice,
    discountedPrice,
    priceDropTimeInMinutes,
    imageSrc,
    bidCount,
    inventoryCount,
    itemHasBeenSold,
    percentageDiscount,
    hasDiscountBiggerThan30Percent,
    auctionWillEndSoon,
  } = useAuctionData({ auction });

  return (
    <Card className={clsx('auction-card h-full', className)}>
      {itemHasBeenSold && (
        <div className="w-full h-full absolute z-20 top-0 left-0">
          <div className="h-1/2 bg-white/80" />
          <CardContent className="h-1/2 bg-white !justify-normal">
            <div className="relative">
              <DiscountedPriceLabel
                discountedPrice={originalPrice === 0 ? undefined : discountedPrice}
                originalPrice={originalPrice || discountedPrice}
                currency={currency}
              />
              <div className="absolute w-full h-full top-0 left-0 bg-white/60" />
            </div>
            <div className="mt-9">Aww, you just missed it!</div>
          </CardContent>
        </div>
      )}
      <CardContent className="!p-0">
        <section className="relative">
          <div className="absolute flex w-full justify-end mt-1 z-10 pr-2">
            {auctionWillEndSoon && (
              <div className="mt-[5px]">
                <AuctionEndSoon className="text-secondary-400 h-6 w-6 relative z-10" />
              </div>
            )}
          </div>
          {imageSrc && !itemHasBeenSold && bidCount >= 1 ? (
            <CardImageLabel>
              <span className="text-white">
                {bidCount} bid{bidCount > 1 ? 's' : ''} already
              </span>
            </CardImageLabel>
          ) : null}
          {imageSrc ? (
            <CardThumbnail
              className="aspect-[3/2] object-contain w-full"
              alt="image-placeholder"
              src={imageSrc || ''}
            />
          ) : (
            <div className="aspect-[3/2] bg-transparent w-full h-full flex justify-center items-center" />
          )}
          {itemHasBeenSold && (
            <div className="w-full h-full absolute top-0 left-0 z-20 flex justify-center items-center ">
              <ProductSold />
            </div>
          )}
        </section>
        <section className="flex flex-col mt-2">
          <div>
            <CardTitle className="line-clamp-2">{auction.product.title}</CardTitle>
            <ProductConditionBadge condition={auction.product.product_condition} />
          </div>
          {inventoryCount > 0 && inventoryCount <= 3 ? (
            <div className="overflow-hidden mb-2">
              <CardLabel className="font-normal" variant="stock">
                Only {inventoryCount} left
              </CardLabel>
            </div>
          ) : (
            <div className="overflow-hidden h-8" />
          )}
          <div className="flex flex-col gap-1">
            <DiscountedPriceLabel
              discountedPrice={originalPrice === 0 ? undefined : discountedPrice}
              originalPrice={originalPrice || discountedPrice}
              percentageDiscount={percentageDiscount}
              hasDiscountBiggerThan30Percent={hasDiscountBiggerThan30Percent}
              currency={currency}
            />
            <div className="hidden md:block text-sm">
              Last price drop was <span className="font-bold">{`${priceDropTimeInMinutes} minutes`}</span> ago
            </div>
          </div>
          <div className="flex flex-col gap-2.5">{isGoodForPets ? <PetOwnersBadge /> : null}</div>
        </section>
      </CardContent>
    </Card>
  );
};
